<template>
  <div>
    <!-- 内容区 -->
    <indexsquare
      :title="this.title"
      :zlyc="this.zlyc"
      :type="this.type"
      :showflag="false"
      :noresult="this.noresult"
    ></indexsquare>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import indexsquare from '@/components/indexcom/index'
import { list } from '@/api/indexapi'
export default {
  components: {
    indexsquare
  },
  data() {
    return {
      title: '搜索结果',
      zlyc: [],
      type: '',
      category_id: '',
      noresult: false
    }
  },
  watch: {
    $route() {
      this.category_id = this.$router.history.current.query.category_id
      this.getlist()
    }
  },
  mounted() {
    this.getlist()
  },
  methods: {
    getlist() {
      const type = Cookies.get('index_type')
      const content = this.$route.query.content
      this.category_id = this.$route.query.category_id
      const info = {
        page: 1,
        category_id: this.category_id,
        keyword: content
      }
      list(info)
        .then((res) => {
          // 单个搜索
          if (res.result.list !== undefined) {
            if (res.result.list.length === 0) {
              this.noresult = true
            } else {
              // 更多京东搜索，更多筑龙原创单个搜索返回结果在List
              this.zlyc = res.result.list
              this.noresult = false
            }
          } else {
            // 同时搜索
            if (
              res.result.zlProduct.length === 0 &&
              res.result.jdProduct.length === 0
            ) {
              this.noresult = true
            } else {
              this.noresult = false
            }
            // 两个一起搜索返回结果在不同的数组上，数组合并
            // 筑龙原创
            if (res.result.zlProduct.length !== 0) {
              this.zlyc = res.result.zlProduct
              if (res.result.jdProduct.length !== 0) {
                this.zlyc = this.zlyc.concat(res.result.jdProduct)
              }
            }
            if (res.result.jdProduct.length !== 0) {
              this.zlyc = res.result.jdProduct
              if (res.result.zlProduct.length !== 0) {
                this.zlyc = this.zlyc.concat(res.result.zlProduct)
              }
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.banner-box {
  border: 1px solid red;
  height: 505px;
  background: pink;
  position: relative;
  .form_line {
    position: absolute;
    left: 50%;
    margin-left: -552px;
    bottom: 10px;
    padding: 0 90px;
    .el_form {
      width: 100%;
      height: 45px;
      line-height: 45px;
      background: white;
    }
  }
}
/deep/.el-input__inner {
  border: 0px;
  border-right: 1px solid #ccc;
  border-radius: 0px;
  height: 29px;
  line-height: 29px;
  margin: 8px 0px;
}
/deep/.el-button--primary {
  height: 100%;
  border-radius: 0px;
  border: 0px;
  float: right;
}
.safe-box {
  width: 1200px;
  margin: 0 auto;
}
// 主题区域
.main-box {
  background: #fff6f7;
}
.big-list {
  width: 1200px;
  margin: 0px auto;
  padding: 20px 0px;
  h3 {
    height: 60px;
    line-height: 60px;
    font-size: 22px;
    color: #ee2d2e;
    padding-right: 10px;
    border-bottom: 1px solid #ccc;
    span {
      border-bottom: 2px solid #ee2d2e;
      float: left;
      height: 100%;
    }
  }
}
.small_box {
  padding: 45px 0px;
  overflow: hidden;
  .small_list {
    background: white;
    width: 255px;
    margin-right: 60px;
    margin-bottom: 45px;
    padding: 30px 30px 20px 30px;
    float: left;
    cursor: pointer;
    img {
      width: 190px;
      height: 160px;
      margin-bottom: 8px;
    }
    h4 {
      font-size: 16px;
      color: #333;
      padding: 0 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .small_list:nth-child(4n) {
    margin-right: 0px;
  }
  .small_count {
    line-height: 35px;
    overflow: hidden;
    img,
    span {
      float: left;
      color: #ee2d2e;
    }
    img {
      width: 20px;
      height: 15px;
      margin: 10px 0px;
      margin-right: 5px;
    }
    em {
      font-style: normal;
      float: right;
      color: #666;
      font-style: 12px;
    }
  }
}
</style>
